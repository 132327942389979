/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.each-fade {
  display: flex;
  width: 100%;
}

.each-fade > div {
  width: 100%;
}

.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-fade p {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}